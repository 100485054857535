<div class="flex flex-col min-h-screen w-screen pt-[70px]">
  <div class="sl-box inline-flex lg:justify-between items-center fixed top-0 bg-white h-[80px] z-40 shadow-lg">
    @if (userService.isValidSession) {
      <ng-container *ngTemplateOutlet="adminSettings"></ng-container>
    }
    <svg
      (click)="openMobileMenu()"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-12 h-12 mr-5 lg:hidden"
    >
      <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
    </svg>

    <!-- Logo starts -->
    <a routerLink="/">
      <div class="flex flex-row items-center gap-6">
        <img src="assets/images/logo.svg" alt="logo" class="h-[70px] w-auto aspect-[1.42]" />
        <div>
          <h3 class="text-gunmetal marcellus text-20">Skin Logic Aesthetics</h3>
          <h4 class="text-gunmetal text-14">formerly Vein Med Solutions</h4>
        </div>
      </div>
    </a>
    <!-- Logo ends -->

    <div class="inline-flex gap-6">
      <div class="hidden lg:inline-flex items-center gap-10">
        <ng-template #singleItem let-menu="menu">
          <a class="inline-flex items-center gap-1 cursor-pointer" [routerLink]="menu.path">
            <h3 class="text-18 text-gunmetal font-normal">{{ menu.title }}</h3>
          </a>
        </ng-template>

        <ng-template #dropdownItem let-menu="menu">
          <h3
            class="inline-flex items-center gap-1 cursor-pointer"
            [routerLink]="menu.path"
            [matMenuTriggerFor]="matMenu"
          >
            <h3 class="text-18 text-gunmetal font-normal">{{ menu.title }}</h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2"
              stroke="currentColor"
              class="w-7 pt-1 h-auto text-gunmetal"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg>
          </h3>
        </ng-template>

        @for (menu of menuItems; track $index) {
          <ng-container
            *ngTemplateOutlet="menu.dropDownKey ? dropdownItem : singleItem; context: { menu: menu }"
          ></ng-container>
        }
      </div>
      <div class="hidden lg:flex">
        <ng-container *ngTemplateOutlet="reservation"></ng-container>
      </div>
    </div>
  </div>
  <ng-content></ng-content>
  <app-footer class="mt-auto"></app-footer>
</div>

<!-- Reservation Button -->
<ng-template #reservation>
  <app-button class="ml-6" (click)="opneBookings()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      class="w-8 h-8 mr-3 text-gunmetal"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
      />
    </svg>
    APPOINTMENT
  </app-button>
</ng-template>

<mat-menu #matMenu="matMenu" yPosition="below" class="!max-w-none">
  <div class="inline-flex gap-10 p-6">
    @for (item of services; track $index) {
      <div class="flex flex-col">
        <h2 class="text-black text-18 font-semibold capitalize pb-4 px-4">{{ item.title }}</h2>
        @for (sub of item.services; track $index) {
          <h2
            (click)="openServices(item.title, $index)"
            class="text-[#21262B] text-16 font-medium capitalize cursor-pointer py-3 px-4 rounded-lg hover:bg-[#F3F4F6]"
          >
            {{ sub }}
          </h2>
        }
      </div>
    }
  </div>
</mat-menu>

<ng-template #adminSettings>
  <div
    class="right-0 top-6 absolute hidden lg:flex bg-gunmetal rounded-l-full py-5 px-6 cursor-pointer z-50"
    (click)="showAdminControls()"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="size-10 text-white"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
      />
      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
    </svg>
  </div>
</ng-template>

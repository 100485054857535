import { ChangeDetectionStrategy, Component, Injector, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { CloudinaryService } from '../../services/cloudinary.service';
import { WidgetFetcher } from '../../types/widgets/fetcher';
import { WidgetEditorComponent } from '../../../features/admin/pages/widget-editor/widget-editor.component';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterLink, WidgetEditorComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent extends WidgetFetcher implements OnInit {
  override pageKey = 'misc';
  payments = ['skinLogic/misc/ae_nyuyoa.png', 'skinLogic/misc/visa_sxseqg.png', 'skinLogic/misc/master_aadw1g.png'];
  socialMedia = [
    {
      title: 'Instagram',
      link: '',
      key: 'instagram',
      image: 'skinLogic/misc/insta_eit6cv.png',
    },
    {
      title: 'Facebook',
      link: '',
      key: 'facebook',
      image: 'skinLogic/misc/fb_azef54.png',
    },
  ];

  constructor(
    public injector: Injector,
    public cloudinary: CloudinaryService,
    private router: Router,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.fetchWidgetData([this.pageKey]);
  }

  get currentYear() {
    return new Date().getFullYear();
  }

  openLink(url: any) {
    if (!url) return;
    window.open(url, '_blank');
  }

  navigate(path: string) {
    this.router.navigate([path], { replaceUrl: false });
  }
}

<div class="flex flex-col">
  <div class="flex flex-col bg-[#555150] sl-box py-20">
    <div class="inline-flex pb-10">
      <h3 #companyName class="text-white marcellus text-32">Skin Logic Aesthetics</h3>
    </div>
    <div class="flex flex-col lg:flex-row gap-20">
      <a href="https://maps.app.goo.gl/fj7g3utzbjWVHaoQA" target="_blank" class="flex-1">
        <div class="flex flex-col">
          <h3 class="text-gray-100 font-semibold text-18 pb-4">Address</h3>
          <h4 class="text-gray-100 font-medium text-16 pb-4">
            Fountain Medical Plaza. 5620 W. Thunderbird Ave. D-2. Glendale, AZ 85306
          </h4>
          @defer (on viewport(companyName); on  timer(12s)) {
            <div class="w-full md:w-[300px]">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d830.6850359291311!2d-112.1809656302953!3d33.61204619833287!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b6972427cbcbd%3A0x5dbbcf5dc4324f46!2s5620%20W%20Thunderbird%20Rd%20suite%20d-2%2C%20Glendale%2C%20AZ%2085306%2C%20USA!5e0!3m2!1sen!2sin!4v1726232495423!5m2!1sen!2sin"
                width="100%"
                height="300"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          } @placeholder {
            <div class="shimmer rounded-lg h-[300px] aspect-1"></div>
          }
        </div>
      </a>

      <div class="flex flex-col flex-1">
        <h3 class="text-gray-100 font-semibold text-18 pb-5">We accept</h3>
        @defer (on viewport(companyName); on  timer(10s)) {
          <div class="inline-flex flex-wrap gap-10">
            @for (url of payments; track $index) {
              <img
                fetchpriority="low"
                class="h-[60px] aspect-[95/60]"
                [src]="cloudinary.getUrl({ id: url, height: 120 })"
                alt="Bank Logo"
              />
            }
          </div>
        } @placeholder {
          <div class="inline-flex flex-wrap gap-10">
            @for (url of payments; track $index) {
              <div class="shimmer rounded-lg h-[60px] aspect-[95/60]"></div>
            }
          </div>
        }

        <div class="flex flex-col flex-1 pt-10">
          <h3 class="text-gray-100 font-semibold text-18 pb-4">Hours of operation</h3>
          <h4 class="text-gray-100 font-medium text-16">Monday to Friday</h4>
          <h4 class="text-gray-100 font-medium text-16">9:00am to 5:00pm</h4>
        </div>
      </div>

      <div class="flex flex-col flex-1">
        <h4 routerLink="/privacy-policy" class="text-gray-100 font-medium text-16 cursor-pointer pb-5">
          Privacy Policy
        </h4>
        <h3 class="text-gray-100 font-semibold text-18 pb-5">Follow us</h3>

        @defer (on viewport(companyName); on  timer(10s)) {
          <widget-editor class="mr-16" [data]="widgetData" [slug]="pageKey" [keys]="['social_media']">
            <div class="inline-flex flex-wrap gap-10">
              @for (item of socialMedia; track $index) {
                <img
                  (click)="openLink(getValue('social_media').getWidget(item.key, 0).value)"
                  fetchpriority="low"
                  class="h-[60px] aspect-1 cursor-pointer"
                  [src]="cloudinary.getUrl({ id: item.image, height: 90 })"
                  [alt]="item.title"
                />
              }
            </div>
          </widget-editor>

          <div class="flex flex-col">
            <h3 class="font-semibold text-18 pt-5 pb-4 text-gray-100">Apply for Credit</h3>
            <div class="flex flex-row items-stretch gap-10 md:gap-0 md:flex-col">
              <img
                src="assets/images/care-credit.png"
                class="md:w-[200px] h-auto flex-1 cursor-pointer"
                (click)="
                  openLink(
                    'https://www.carecredit.com/apply/confirm.html?encm=AmMKMwFjXDlVaQVhU2ZdNQU3Xj5WNAQ0D2oHMQRoVmM'
                  )
                "
              />

              <div
                class="md:w-[200px] flex bg-white md:mt-8 p-6 flex-1 cursor-pointer"
                (click)="openLink('assets/cherry.html')"
              >
                <img src="assets/images/cherry-logo.svg" class="w-full h-auto m-auto" />
              </div>
            </div>
          </div>
        } @placeholder {
          <div class="inline-flex flex-wrap gap-10">
            @for (item of socialMedia; track $index) {
              <div class="shimmer rounded-lg h-[60px] aspect-1"></div>
            }
          </div>
        }
      </div>
    </div>

    <!-- <div class="inline-flex gap-20 pt-16">
      <div class="flex flex-col flex-1">
        <h3 class="text-gray-100 font-semibold text-18 pb-4">Hours of operation</h3>
        <h4 class="text-gray-100 font-medium text-16">Monday to Friday</h4>
        <h4 class="text-gray-100 font-medium text-16">9:00am to 5:00pm</h4>
      </div>

      <div class="flex flex-col flex-1">
        <h4 routerLink="/privacy-policy" class="text-gray-100 font-medium text-16 cursor-pointer">Privacy Policy</h4>
      </div>

      <div class="flex flex-col lg:flex-1"></div>
    </div> -->
  </div>
  <div class="inline-flex items-center justify-center bg bg-darkBrown py-8">
    <h3 class="text-white text-16">Copyright {{ currentYear }} Skin Logic Aesthetics</h3>
  </div>
</div>
